<script>

import helpers from "../../helpers";
import _debounce from 'lodash.debounce';
import {
  broadcastMethods
} from "@/state/helpers";

export default {
  data() {
    return {
      executions: [],
      currentPage:1,
			perPage:11,
      rows:0,
      query:'',
      broadcastId: ''
    };
  },
  components:{},
  props: {
    broadcast: Object
  },
  mounted(){
    
  },
  created(){
		this.debounceExecutions = _debounce(this.searchExecutions.bind(this), 1000);
	},
  computed: {
    
    
  },
  methods: {
    ...broadcastMethods,
    ...helpers,
    searchExecutions(query){
      let loader = this.$loading.show();
      this.isLoading = true;
      
      this.query = query;

      if(query){
        this.executions=[];
        this.currentPage = 1;
				const params = {
        broadcastId:this.broadcastId,
        q: `customerId=${query}&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}`
      }
        
        this.getBroadcastExecutions(params).then((res)=>{
          if(res && res.data){
            this.executions = res.data.data;
            this.rows = res.data.pagination.totalItems;
          }
          this.isLoading = false;
          loader.hide();
        });
      }else{
        this.isLoading = false;
        this.currentPage = 1;
        loader.hide();
        this.laodBradcastExecutions(this.broadcastId);
      }

    },
    laodBradcastExecutions(broadcastId){
      let loader = this.$loading.show();
      const params = {
        broadcastId,
        q: `limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}`
      }
      this.getBroadcastExecutions(params).then((res)=>{
        this.executions = res.data.data;
        this.rows = res.data.pagination.totalItems;

      }).catch((ex)=>{
        // eslint-disable-next-line no-console
        console.log(ex);
      }).finally(()=>{
        loader.hide();
      })
    },
    onExecutionsListPageClicked(){
      this.laodBradcastExecutions(this.broadcast?._id)
    }
  },
  watch:{
    broadcast: {
      immediate:true,
      handler(newVal){
        if(newVal){
          this.broadcastId = newVal._id;
          this.laodBradcastExecutions(newVal._id);
        }
      }
    }
  }
};
</script>

<template>
  <div class="row ">
    <div class="col">
    <div class="card">
				<div class="card-body p-0">
          <div class="row ">
            <div class="col-sm-4">
                <div class="search-box me-2 mb-0 d-inline-block">
                  <div class="position-relative">
                      <input
                      type="text"
                      class="form-control"
                      :placeholder="$t('common.search')"
                      @input="debounceExecutions($event.target.value)"
                      v-model="query"
                      />
                      <i class="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
            </div>
            <div class="col-sm-8">
                <div class="text-sm-end">
                
                </div>
            </div>
          </div>
        <div class="table-responsive" v-if="executions.length> 0" style=" margin-top: var(--bs-modal-padding);">
            <table  id="my-table" class="table align-middle table-nowrap table-hover" style="background-color: white;">
                    <thead class="table-light">
                        <tr>
                          <th scope="col" v-if="broadcast?.type=='email'">Email</th>
                          <th scope="col" v-else>Id</th>
                          <th scope="col">{{$t('common.date')}}</th>
                          <th scope="col">{{$t('broadcasts.broadcast_execution_status_sent')}}</th>
                          <th scope="col">{{$t('broadcasts.broadcast_execution_status_opened')}}</th>
                          <th scope="col">{{$t('broadcasts.broadcast_execution_status_clicked')}}</th>
                          <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="execution in executions" :key="execution._id">
                            <td v-if="broadcast?.type=='email'">{{ execution.customerId }}</td>
                            <td v-else>{{ execution.customerId }}</td>
                            <td>{{ `${parseDateWithLocale(new Date(execution.date),{ month: 'short', day: 'numeric',year:'numeric'})} ${parseTimeWithLocale(new Date(execution.date),{hour: '2-digit', minute:'2-digit'})}`}}</td>
                            <td>{{ execution.success == true ? `${$t('common.yes')}` : `${$t('common.no')}` }}</td>
                            <td>{{ execution.notificationOpened ? `${$t('common.yes')}` : `${$t('common.no')}` }}</td>
                            <td>{{ execution.notificationClicked ? `${$t('common.yes')}` : `${$t('common.no')}` }}</td>
                        </tr>
                    </tbody>
            </table>
        </div>
      </div>
    </div>
        <div class="row mb-0" v-if="rows > perPage">
            <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                    <ul class="pagination pagination-rounded mb-0">
                        <b-pagination
                            @input="onExecutionsListPageClicked"
                            v-model="currentPage"
                            :total-rows="rows"
                            :per-page="perPage">
                        </b-pagination>
                    </ul>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>
