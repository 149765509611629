<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import _debounce from 'lodash.debounce';
import EmptyList from "@/components/widgets/empty_list"
import helpers from '../../../helpers'
import AbTestingReports from "@/components/broadcasts/ab-testing-reports";
import Drawer from "vue-simple-drawer";
import FilterP from "@/components/broadcasts/filter";
import NewBroadcast from "@/components/broadcasts/new-broadcast";
import ReceptorsActivity from "@/components/broadcasts/receptors-activity";

import {
	broadcastMethods,
  projectMethods,
  audienceMethods
} from "@/state/helpers";

export default {
  page: {
    title: "Broadcasts",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    EmptyList,
    AbTestingReports,
    Drawer,
    FilterP,
    NewBroadcast,
    ReceptorsActivity
  },
  data() {

    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    // 15th two months prior
    const minDate = new Date(today)

    return {
      title: "",
      items: [],
			currentPage:1,
			perPage:10,
      rows:0,
			isLoading:false,
      broadcasts:[],
      confirmDelete:false,
      query:'',
      empty_list_config:{
            "title" : this.$t('broadcasts.empty_search_title'),
            "linkButtonText" : this.$t("common.reset_search"),
            icon: "fa-cubes"
        },
        empty_config:{
          "title" : this.$t('broadcasts.empty'),
            "subtitle" : this.$t('broadcasts.empty_subtitle'),
            "buttonText" : this.$t('broadcasts.new'),
            icon: "fa-cubes"
        },
        showNewBroadcastModal: false,
        broadcast:null,
        showSend: false,
        settings:{
          send: 'now'
        },
        showScheduleForm: false,
        verifiedBroadcast: false,
        min: minDate,
        totalCount:0,
        audienceSize: '-',
        isScheduledValid : true,
        isAudienceSizeValid: true,
        isVariantsValid: true,
        showTesingInfo:false,
        openRightDrawer: false,
        filters: [], 
        showNewBroadcast: false,
        showReceptosActivity: false
        
    };
  },
  created(){
		this.debounceBroadcasts = _debounce(this.searchBroadcasts.bind(this), 1000);
	},
	mounted(){
		this.loadBroadcasts();
    //this.loadAllCustomersCount();
  },
  computed:{
    broadcastOkSubtitle(){
      const audience = this.broadcast?.audience?.type == 'all' ? this.totalCount :  this.broadcast?.audience?.type == 'list' ? this.audienceSize : this.broadcast?.audience?.type == 'audience' ?  this.audienceSize : '0';
      if(this.broadcast?.variants?.length>1){
        return this.$t('broadcasts.review_with_ab_testing').replace('#',this.$t(`broadcasts.channel_${this.broadcast?.type}`)).replace('##',audience).replace('###',this.broadcast.variants.length)
      }else{
        return this.$t('broadcasts.review_without_ab_testing').replace('#',this.$t(`broadcasts.channel_${this.broadcast?.type}`)).replace('##',audience)
      }
    },
    broadcastErrorSubtitle(){
      let text = ''

      if(!this.isAudienceSizeValid){
        text+= this.broadcast.audience.type == 'all' ?  this.$t('broadcasts.audience_all_error') : this.$t('broadcasts.audience_error')
      }
      if(!this.isVariantsValid){
        text+= this.$t('broadcasts.variants_error')
      }
      if(!this.isScheduledValid){
        text+= this.$t('broadcasts.schedule_date_required');
      }
      
      return text;
    }
  },
  methods:{
    ...broadcastMethods,
    ...projectMethods,
    ...helpers,
    ...audienceMethods,

    getCustomFilterQuery(){
      let q = '';
      if(this.filters.length>0){
        this.filters.forEach((f)=>{
          if(f.key == 'goal'){
            q+= `, "goal._id": "${f.value}"`
          }else{
            q+= `, "${f.key}": "${f.value}"`
          }
          
        });
      }
      return q;
    },

    loadAllCustomersCount(excludeId){
      const payload = {
        channel: this.broadcast?.type == 'app_push' ? 'push' : this.broadcast?.type, 
        project: localStorage.getItem('current_project'),
        include_audience : [],
        exclude_audience: excludeId? [excludeId] : []
      }

      this.countCustomerBroadcast(payload).then((res)=>{
        this.totalCount = res.data?.count;
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t('customers.get_customers_error'),title:  this.$t('customers.title') });
      }).finally(()=>{
        this.isLoading = false;
        this.isBroadcastValid()
      })
    },

    loadAudience(id,excludeId){
      
      const payload = {
        channel: this.broadcast?.type == 'app_push' ? 'push' : this.broadcast?.type, 
        project: localStorage.getItem('current_project'),
        include_audience : [id],
        exclude_audience: excludeId? [excludeId] : []
      }

      this.countCustomerBroadcast(payload).then((res)=>{
        this.audienceSize = res.data?.count;
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t('customers.get_customers_error'),title:  this.$t('customers.title') });
      }).finally(()=>{
        this.isLoading = false;
        this.isBroadcastValid()
      })
      
    },
    loadContactList(id,excludeId){
      const payload = {
        channel: this.broadcast?.type == 'app_push' ? 'push' : this.broadcast?.type, 
        project: localStorage.getItem('current_project'),
        include_contact_list : [id],
        exclude_audience: excludeId? [excludeId] : []
      }

      this.countCustomerBroadcast(payload).then((res)=>{
        this.audienceSize = res.data?.count;
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t('customers.get_customers_error'),title:  this.$t('customers.title') });
      }).finally(()=>{
        this.isLoading = false;
        this.isBroadcastValid()
      })
    },
    loadBroadcasts(){
			
      let loader = this.$loading.show();
      this.isLoading = true;
      
      let filter = '';
      if(this.filters.length > 0){
        filter = this.getCustomFilterQuery();
      }

      const params = {
				q : `whereObj={"project":"${localStorage.getItem('current_project')}"${filter}}&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}`
			}

			this.getBroadcasts(params).then((res)=>{
        if(res && res.data){
          this.broadcasts= res.data;
          this.rows= res.totalCount
				}
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t('broadcasts.get_broadcasts_error'),title: this.$t('broadcasts.title') });
      }).finally(()=>{
        this.isLoading = false;
        loader.hide();
      });
    },
    searchBroadcasts(query){
      
			let loader = this.$loading.show();
      this.isLoading = true;
      this.query = query;
      this.empty_list_config.subtitle = this.$t('broadcasts.empty_search_text').replaceAll('#', this.query);

      if(query){
        this.broadcasts=[];
        this.currentPage = 1;
        
        let filter='';

        if(this.filters.length > 0){
        filter = this.getCustomFilterQuery();
      }
        const params = {
          q : `whereObj={"name": {"$regex" : ".*${query}*." , "$options": "i"}, "project":"${localStorage.getItem('current_project')}"${filter}}&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}`
        }
        
        this.getBroadcasts(params).then((res)=>{
          if(res && res.data){
            this.broadcasts= res.data;
            this.rows= res.totalCount;
          }
          this.isLoading = false;
          loader.hide();
        });
				
      }else{
        this.isLoading = false;
        this.currentPage = 1;
        loader.hide();
        this.loadBroadcasts();
      }
    },
		onBroadcastListPageClicked(){
			this.loadBroadcasts();
		},	
    onResetSearchClicked(){
      this.query = '';
      this.loadBroadcasts();
    },
    onCreateBroadcastClicked(channel){
      /*this.$router.push({
            path: `/broadcast?channel=${channel}`,
            }
        ).catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error)
        });*/

      this.broadcast = {
        type: channel,
        audience:{
          type: 'all',
        },
        variants:[],
        settings: {
          test_ratio: 10,
          testing_duration: 1
        },
        goal: {
          _id: 'purchase',
          time : 24
        }
      };
      this.showNewBroadcastModal = false;
      this.showNewBroadcast = true;
    },
    onRemoveBroadcast(data){
      this.broadcast = data;
      this.confirmDelete = true;
    },
    onEditBroadcastClicked(data){
      this.$router.push({
            path: `/broadcast?id=${data._id}`,
            }
        ).catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error)
        });
    },
    onConfirmRemoveBroadcastClicked(){
      let loader = this.$loading.show();

      this.deleteBroadcast(this.broadcast._id).then(()=>{
        this.currentPage=1;
        this.loadBroadcasts();
        this.$notify({ type: 'success', text: this.$t('broadcasts.delete_broadcast_success'),title:  this.$t('broadcasts.title') });
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t('broadcasts.delete_broadcast_error'),title:  this.$t('broadcasts.title') });
      }).finally(()=>{
        loader.hide();
      })
    },
    onSendBroadcastClicked(data){
      const loader = this.$loading.show();

      this.getBroadcast(data._id).then((res)=>{
        this.broadcast = res.data;
        this.settings = data.settings;
        if(!this.settings.send)
          this.settings.send = 'now';
        this.onSendOrScheduledSelected(this.settings.send)
        this.isBroadcastValid();
        if(this.broadcast.audience.type=='all'){
          this.loadAllCustomersCount(this.broadcast.excludeAudience?.audience);
        }else if(this.broadcast.audience.type == 'audience'){
          this.loadAudience(this.broadcast.audience.audience, this.broadcast.excludeAudience?.audience);
        }else if(this.broadcast.audience.type == 'list'){
          this.loadContactList(this.broadcast.audience.contactList, this.broadcast.excludeAudience?.audience)
        }
        
        this.showSend = true;
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t('broadcasts.get_broadcast_error'),title: this.$t('broadcasts.title')});
      }).finally(()=>{
        loader.hide();
      })

      
      
    },
    onSendOrScheduledSelected(type){
      this.settings.send = type;
      this.$set(this.settings, 'send', type);

      this.isBroadcastValid();
      this.showScheduleForm = type == 'schedule';
    },
    onConfirmSendClicked(){
      let loader = this.$loading.show();

      if(this.settings.send == 'schedule'){
        this.settings.offset = this.getTimezoneOffset()
      }
      
      const body = {
        _id: this.broadcast._id,
        settings : this.settings,
        status: this.settings.send == 'now' ? 'running' : 'scheduled'
      }
      this.updateBroadcast(body).then(()=>{
        this.$notify({ type: 'success', text: this.$t('broadcasts.update_broadcast_success'),title:  this.$t('broadcasts.title') });
        this.showSend = false;
        this.currentPage = 1;
        this.query = '';
        this.loadBroadcasts();
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t('broadcasts.update_broadcast_error'),title:  this.$t('broadcasts.title') });
      }).finally(()=>{
        loader.hide();
      })
    },
    validateAudience(audience){
    
      if(audience?.type == 'all'){
        this.audienceSize = this.totalCount;
        this.isAudienceSizeValid = this.audienceSize > 0;
        return this.isAudienceSizeValid
      }
      else if(audience?.type == 'audience'){
        this.isAudienceSizeValid = audience.audience && audience.audience !='' && this.audienceSize > 0;
        return this.isAudienceSizeValid;
      }else if(audience?.type == 'list'){
        this.isAudienceSizeValid = this.audienceSize > 0;
        return this.isAudienceSizeValid;
      }

      return false;
    },
    validateSchedule(){
      if(this.settings.send == 'schedule'){
        this.isScheduledValid = this.settings.date && this.settings.time
        return this.isScheduledValid;
      }

      return true;
    },
    validateVariants(){
      this.isVariantsValid = this.broadcast.variants && this.broadcast.variants.length > 0
      return this.isVariantsValid
    },
    isBroadcastValid(){
      this.showScheduleForm = this.settings?.send == 'schedule';
      let isOk = false;
      
      isOk = this.validateAudience(this.broadcast.audience);
      isOk = this.validateVariants() && isOk 
      isOk = this.validateSchedule() && isOk;

      this.verifiedBroadcast = isOk;
    },
    onScheduleDateSelected(){
      this.isBroadcastValid();
    },
    onScheduleTimeSelected(){
      this.isBroadcastValid();
    },
    onABTestingClicked(data){
      const loader = this.$loading.show();

      this.getBroadcast(data._id).then((res)=>{
        this.broadcast = res.data;
        this.settings = data.settings;
        this.broadcast.variants.forEach(v => {
          v.opened = this.broadcast.opened && this.broadcast.opened[v._id] ? this.broadcast.opened[v._id] : 0;
          v.race = this.broadcast.race && this.broadcast.race[v._id] ? this.broadcast.race[v._id] : 0; 
          v.sent = this.broadcast.sent && this.broadcast.sent[v._id] ? this.broadcast.sent[v._id] : 0;
          v.winner = this.broadcast.winner && this.broadcast.winner._id == v._id ? true : false 
          v.conversion_rate = v.sent > 0 ? v.race/v.sent * 100 : 0
        });

        if(this.broadcast.winner && this.broadcast.winner){
          this.broadcast.variants.sort((a, b) => {
					if (a._id === this.broadcast.winner) {
						return -1; 
					}
					if (b._id === this.broadcast.winner) {
						return 1; 
					}
					return 0; 
				});
        }else{
          this.broadcast.variants.sort(function(a, b) {
              return b.conversion_rate - a.conversion_rate;
          });
        }
        this.showTesingInfo = true;
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t('broadcasts.get_broadcast_error'),title: this.$t('broadcasts.title')});
      }).finally(()=>{
        loader.hide();
      })
    },
    getTimezoneOffset() {
      const now = new Date();
      const timezoneOffsetMinutes = now.getTimezoneOffset();
      const hours = Math.floor(Math.abs(timezoneOffsetMinutes) / 60);
      const minutes = Math.abs(timezoneOffsetMinutes) % 60;
      const sign = timezoneOffsetMinutes > 0 ? "-" : "+";
      const formattedOffset = `${sign}${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
      return formattedOffset;
    },
    onGetLogClicked(data){
      window.open(data.logURL);
    },
    rightDrawer(){
      this.openRightDrawer = false;
    },
    removeAllFilter(){
      this.$refs.filterRef.removeAll();

      this.applyCustomFilter();
    },
    applyCustomFilter(){
      this.openRightDrawer = false;

      this.filters = this.$refs.filterRef.getAllFilters();
      this.currentPage = 1;
      this.searchBroadcasts(this.query);
    },
    onFilterClicked(){
      this.openRightDrawer=true; 
      setTimeout(()=>{
        this.$refs.filterRef.setAllFilter(this.filters)
      },1000)
    },
    onRemoveFilter(filter){
      this.filters = this.filters.filter(f=> f.key != filter.key);

      this.currentPage = 1;
      this.searchBroadcasts(this.query);
    },

    onNewBroadcastConfirm(broadcast){
      let loader = this.$loading.show();
      broadcast.project = localStorage.getItem('current_project');
      broadcast.status = 'draft';
      
      this.createBroadcast(broadcast).then(()=>{
        this.$notify({ type: 'success', text: this.$t('broadcasts.create_broadcast_success'),title:  this.$t('broadcasts.title') });
        this.showNewBroadcast = false;
        this.broadcast = null;
        this.currentPage = 1;
        this.query = '';
        this.loadBroadcasts()
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t('broadcasts.create_broadcast_error'),title:  this.$t('broadcasts.title') });
      }).finally(()=>{
        loader.hide();
      })
    },
    onNewBroadcastCancel(){
      this.showNewBroadcast = false;
    },
    onActivityClicked(broadcast){
      this.broadcast = broadcast;
      this.showReceptosActivity = true;
    },
    onAnalyticsClicked(data){
      this.$router.push({
            path: `/broadcast-analyze?broadcast=${data._id}`,
            }
        ).catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error)
        });
    },
    onAnalyzeClicked(){
      this.$router.push({
            path: `/broadcast-analyze`,
            }
        ).catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error)
        });
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('broadcasts.title')" :items="items" />
    <div class="row">
      <div class = "col">
        <div class="card mb-3">
				<div class="card-body" :class="filters.length > 0 ? 'pb-2' : ''">
          <div class="row ">
            <div class="col-sm-4 d-inline-flex">
                <div class="search-box me-2 mb-0 d-inline-block">
                  <div class="position-relative">
                      <input
                      type="text"
                      class="form-control"
                      :placeholder="$t('common.search')"
                      @input="debounceBroadcasts($event.target.value)"
                      v-model="query"
                      />
                      <i class="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
                <ul class="nav nav-pills product-view-nav" v-on:click="onFilterClicked" v-if="false">
                  <li class="nav-item">
                    <a class="nav-link active" href="javascript: void(0);">
                      <i class="bx bx-filter align-middle"></i>
                    </a>
                  </li>
                </ul>
            </div>
            <div class="col text-sm-end">
              <button
                      type="button"
                      class="btn btn-primary mb-0 me-2"
                      @click="showNewBroadcastModal = true"
                      v-if="broadcasts.length>0 || query!=''">
                  <i class="mdi mdi-plus me-1"></i> {{$t('broadcasts.new')}}
              </button>

              <b-dropdown ref="actionsDropdown" ngbDropdown menu-class="dropdown-menu-sm dropdown-menu-end pb-0" right variant="outline-primary" v-if="broadcasts.length>0 || query!=''">
                    <template v-slot:button-content class="ms-2">
                      <i class="mdi mdi-apps me-1 text-primary"></i>
                      <span class="d-none d-sm-inline-block" ngbDropdownToggle>
                        {{$t('common.actions')}}
                        <i class="mdi mdi-chevron-down text-primary"></i>
                      </span>
                    </template>
                    
                    <a class="dropdown-item" href="javascript: void(0);" v-on:click="onFilterClicked">
                      <i class="mdi mdi-filter-variant"></i>
                      {{ $t('common.filter') }}
                    </a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="javascript: void(0);" v-on:click="onAnalyzeClicked">
                      <i class="mdi mdi-chart-line"></i>
                      {{ $t('menu.search_analyze') }}
                    </a>
										<div class="dropdown-divider mb-0"></div>
              </b-dropdown>
            </div>
          </div>
          <div class="badge bg-primary m-1 mt-2 mb-0" v-for="f in filters" :key="f.key">
            <div class="d-flex align-items-center justify-content-between">
                <span class="pe-2">{{ f.badge }}</span>
                <i class="fa fa-times interact " v-on:click="onRemoveFilter(f)"></i>
            </div>
          </div>
          <EmptyList :config="empty_list_config" v-if="!isLoading && broadcasts.length == 0 && query!=''" @onButtonClicked="onResetSearchClicked" class="mt-3 mb-3"/>
          <EmptyList :config="empty_config" v-if="!isLoading && broadcasts.length == 0 && query ==''" @onButtonClicked="()=>showNewBroadcastModal = true" class="mt-3 mb-3"/>
        </div>
          <div class="col-lg-12" v-if="!isLoading && broadcasts.length>0">
            <div>
              <div class="table-responsive">
                <table class="table align-middle table-nowrap table-hover mb-0">
                  <thead class="table-light">
                    <tr>
                      <th scope="col">{{ $t('broadcasts.name')}}</th>
                      <th scope="col">{{ $t('broadcasts.channel')}}</th>
                      <th scope="col" class="text-end">{{ $t('broadcasts.status')}}</th>
                      <th scope="col" class="text-end"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="data in broadcasts" :key="data.id">
                      <td>
                        <h5 class="text-truncate font-size-14 mb-0 interact" v-on:click="onEditBroadcastClicked(data)" >
                          <a class="text-dark">{{
                            data.name
                          }}</a>
                        </h5>
                        <p class="text-muted mb-0 small interact">{{data.description}}</p>
                      </td>
                      <td>
                        {{ $t(`broadcasts.channel_${data.type}`) }}
                      </td>
                      <td class="text-end">
                        <span class="badge font-size-10"
                          :class="{
                          'bg-success': `${data.status}` == 'finished',
                          'bg-warning': `${data.status}` == 'draft',
                          'bg-info': `${data.status}` == 'scheduled',
                          'bg-primary': `${data.status}` == 'running' || `${data.status}` == 'testing'}">
                            {{ $t(`broadcasts.status_${data.status}`) }}
                        </span>
                      </td> 
                      <td class="text-end">
                        <i class="fas fa-chart-line text-primary me-2 interact" v-on:click="onAnalyticsClicked(data)" v-if="data.status=='finished'"></i>
                        <i class="fas fa-clipboard-list text-primary me-2 interact" v-on:click="onActivityClicked(data)" v-if="data.status=='finished'"></i>
                        <!--<i class="fas fa-clipboard-list text-primary me-2 interact" v-on:click="onGetLogClicked(data)" v-if="data.status=='finished' && data.logURL"></i>-->
                        <i class="fas fa-flask text-primary me-2 interact" v-on:click="onABTestingClicked(data)" v-if="(data.status=='testing' || data.status=='finished') && data.variants?.length > 1 "></i>
                        <i class="fas fa-paper-plane text-primary me-2 interact" v-on:click="onSendBroadcastClicked(data)" v-if="data.status=='draft' || data.status == 'scheduled' || data.status == 'running'"></i>
                        <i class="fas fa-edit text-success me-2 interact" v-on:click="onEditBroadcastClicked(data)" v-if="data.status=='draft' || data.status == 'scheduled'"></i>
                        <i class="fas fa-trash-alt text-danger me-1 interact" v-on:click="onRemoveBroadcast(data)" v-if="data.status=='draft' || data.status == 'scheduled'"></i>
                      </td> 
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3" v-if="!isLoading && rows > perPage">
          <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                      <b-pagination
                          @input="onBroadcastListPageClicked"
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage">
                        </b-pagination>
                  </ul>
              </div>
          </div>
        </div>
        <b-modal v-model="confirmDelete" id="modal-center" centered :title="$t('common.confirm')" title-class="font-18" @ok="onConfirmRemoveBroadcastClicked"  :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')">
            <p>{{$t('broadcasts.remove_broadcast')}}</p>
        </b-modal>
        <b-modal
          :title="$t('broadcasts.send_to')"
          hide-footer
          v-model="showSend">
          <div class="row justify-content-center align-items-center">
            <div class="col-sm-4">
              <div class="mb-0">
                <label class="card-radio-label mb-2">
                  <input
                    type="radio"
                    name="send"
                    id="buycurrencyoption1"
                    class="card-radio-input"
                    :checked="settings.send == 'now'"
                    @input="onSendOrScheduledSelected('now')"
                  />
                  <div class="card-radio">
                    <div>
                      <i class="mdi mdi-send font-size-24 text-primary align-middle me-2"></i>
                      <span>{{$t('broadcasts.now')}}</span>
                    </div>
                  </div>
                </label>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="mb-0">
                <label class="card-radio-label mb-2">
                  <input
                    type="radio"
                    name="send"
                    id="buycurrencyoption1"
                    class="card-radio-input"
                    :checked="settings.send == 'schedule'"
                    @input="onSendOrScheduledSelected('schedule')"/>
                  <div class="card-radio">
                    <div>
                      <i class="mdi mdi-send-clock font-size-24 text-primary align-middle me-2"></i>
                      <span>{{$t('broadcasts.schedule')}}</span>
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </div>
          <div class=" w-100 d-inline-flex align-items-baseline bg-primary bg-gradient p-2 mt-3" v-if="verifiedBroadcast && audienceSize != '-'">
            <i class="bx bx-info-circle font-size-18 me-2 text-white align-self-center"/>
            <div>
              <p class="mb-0 me-2 text-white small"> {{ broadcastOkSubtitle }} </p>
              <p class="mb-0 me-2 text-white small" v-if="settings.send == 'schedule' && settings.date && settings.time"> {{ $t('broadcasts.schedule_subtitle').replace('#', parseDateWithLocale(settings.date, {year: 'numeric', month: 'long', day: 'numeric'})).replace('##', settings.time) }} </p>
            </div>
          </div>
          <div class=" w-100 d-inline-flex align-items-baseline bg-danger bg-gradient p-2 mt-3" v-if="!verifiedBroadcast && audienceSize != '-'">
            <i class="bx bx-error font-size-18 me-2 text-white align-self-center"/>
            <div>
              <p class="mb-0 me-2 text-white small" v-html="broadcastErrorSubtitle"></p>
            </div>
          </div>
          <div class="row mt-3" v-if="showScheduleForm">
            <div class="col-6">
              <label>{{$t('broadcasts.date')}}</label>
              <b-form-input type="date" class="form-control w-100 me-2" v-model="settings.date" @input="onScheduleDateSelected" :min="min"></b-form-input>
            </div>
            <div class="col-6">
              <label>{{$t('broadcasts.time')}}</label>
              <b-form-input type="time" class="form-control w-100 me-2" v-model="settings.time" @input="onScheduleTimeSelected"></b-form-input>
            </div>
          </div>
          <div class="text-end pt-5 mt-3">
            <b-button variant="secondary" @click="showSend=false">{{$t('common.cancel')}}</b-button>
            <b-button variant="primary" class="ms-1" @click="onConfirmSendClicked" :disabled="!verifiedBroadcast">{{$t('common.confirm')}}</b-button>
          </div>
        </b-modal>
        <b-modal
        v-model = "showNewBroadcastModal"
        title-class="font-18"
        hide-footer
        :title="$t('broadcasts.new')">
        <div class="list-group list-group-flush">
          <a href="javascript:void(0);" class="list-group-item text-muted py-2 px-0" v-on:click="onCreateBroadcastClicked('email')">
              <div class="d-flex align-items-center">
                <div class="me-3">
                  <img
                    src="@/assets/images/brands/email.png"
                    alt=""
                    class="avatar-sm h-auto d-block rounded"
                  />
                </div>
                <div class="flex-grow-1 overflow-hidden">
                  <h4 class="font-size-13 text-truncate mb-1">
                    {{$t('broadcasts.channel_email')}}
                  </h4>
                  <p class="small mb-0">{{ $t('broadcasts.channel_email_title') }}</p>
                </div>
              </div>
            </a>
            <a href="javascript:void(0);" class="list-group-item text-muted py-2 px-0" v-on:click="onCreateBroadcastClicked('sms')">
              <div class="d-flex align-items-center">
                <div class="me-3">
                  <img
                  src="@/assets/images/brands/smartphone.png"
                    alt=""
                    class="avatar-sm h-auto d-block rounded"
                  />
                </div>
                <div class="flex-grow-1 overflow-hidden">
                  <h4 class="font-size-13 text-truncate mb-1">
                    {{ $t('broadcasts.channel_sms') }}
                  </h4>
                  <p class="small mb-0">{{ $t('broadcasts.channel_sms_title') }} </p>
                </div>
              </div>
            </a>
            <a href="javascript:void(0);" class="list-group-item text-muted py-2 px-0" v-on:click="onCreateBroadcastClicked('app_push')">
              <div class="d-flex align-items-center">
                <div class="me-3">
                  <img
                  src="@/assets/images/brands/alarm.png"
                    alt=""
                    class="avatar-sm h-auto d-block rounded"
                  />
                </div>
                <div class="flex-grow-1 overflow-hidden">
                  <h4 class="font-size-13 text-truncate mb-1">
                    {{ $t('broadcasts.channel_app_push') }}
                  </h4>
                  <p class="small mb-0">{{ $t('broadcasts.channel_app_push_title') }}</p>
                </div>
              </div>
            </a>
            <a href="javascript:void(0);" class="list-group-item text-muted py-2 px-0" v-on:click="onCreateBroadcastClicked('web_push')">
              <div class="d-flex align-items-center">
                <div class="me-3">
                  <img
                  src="@/assets/images/brands/notification-bell.png"
                    alt=""
                    class="avatar-sm h-auto d-block rounded"
                  />
                </div>
                <div class="flex-grow-1 overflow-hidden">
                  <h4 class="font-size-13 text-truncate mb-1">
                    {{ $t('broadcasts.channel_web_push') }}
                  </h4>
                  <p class="small mb-0">{{ $t('broadcasts.channel_web_push_title') }}</p>
                </div>
              </div>
            </a>
            <a href="javascript:void(0);" class="list-group-item text-muted py-2 px-0" v-on:click="onCreateBroadcastClicked('whatsapp')">
              <div class="d-flex align-items-center">
                <div class="me-3">
                  <img
                    src="@/assets/images/brands/whatsapp.png"
                    alt=""
                    class="avatar-sm h-auto d-block rounded"
                  />
                </div>
                <div class="flex-grow-1 overflow-hidden">
                  <h4 class="font-size-13 text-truncate mb-1">
                    {{ $t('broadcasts.channel_whatsapp') }}
                  </h4>
                  <p class="small mb-0">{{ $t('broadcasts.channel_whatsapp_title') }}</p>
                </div>
              </div>
            </a>
          </div>
        </b-modal>
        <b-modal
          v-model='showTesingInfo'
          title="A/B Tests"
          title-class="font-18"
          id="modal-xl"
					size="xl"
          hide-footer>
          <div>
            <AbTestingReports :variants="broadcast?.variants || []" :winner="broadcast?.winner" />
          </div>
        </b-modal>
        <Drawer
          @close="rightDrawer"
          :align="'right'"
          :closeable="true"
          :maskClosable="true"
          :zIndex="1002"
          class="h-100">
          <div v-if="openRightDrawer" class="h-100">
              <div class="offcanvas-header">
                  <h4 class="offcanvas-title" id="offcanvasExampleLabel">
                    <i class="bx bx-gear font-size-16 align-middle me-1"></i>
                    {{$t('filter.title')}}
                  </h4>
              </div>
              <hr class="mt-1" />
              <div class="offcanvas-body flex-grow-1 h-100">
                <div>
                  <FilterP ref="filterRef"></FilterP>
                </div>
                <div class="position-fixed bottom-0 end-0 p-3">
                  <button class="btn btn-secondary me-2" @click="removeAllFilter">{{$t('filter.clean')}}</button>
                  <button class="btn btn-primary me-2" @click="applyCustomFilter">{{$t('filter.apply')}}</button>
                </div>
              </div>
            </div>
        </Drawer>
        <b-modal v-model="showNewBroadcast"
          id="modal-center" 
          :title="`${$t('broadcasts.new')} - ${$t(`broadcasts.channel_${broadcast?.type}`)}`" 
          title-class="font-18"
          hide-footer>
              <NewBroadcast @createBroadcast="onNewBroadcastConfirm" @cancelCreateBroadcast="onNewBroadcastCancel" :broadcast="broadcast"></NewBroadcast>
        </b-modal>
        <b-modal v-model="showReceptosActivity"
          id="modal-center" 
          :title="`Activity - ${broadcast?.name}`" 
          title-class="font-18"
          size="xl"
          hide-footer>
              <ReceptorsActivity :broadcast="broadcast"></ReceptorsActivity>
        </b-modal>
      </div>
    <!-- end row -->
  </Layout>
</template>

<style scoped>
.interact {
  cursor: pointer;
}
</style>